<template>
  <div class="collection">
    <div class="collection__header">
      <h1 class="collection__header_title">Categories</h1>
    </div>

    <div class="collection__main">
      <BaseButton
        v-for="(category, index) in categoriesList.main_categories"
        :key="`main-${index}`"
        class="button--xxl button--outline-white button--oval"
        :text="category.name"
        @click.native="goToPage('Category', category.slug)"
      />
    </div>

    <p class="collection__main_title">Other Categories</p>
    <div v-if="sortedMainCategoriesList.length > 0" class="collection__body">
      <div
        v-for="(column, index) in sortedMainCategoriesList"
        :key="`column-${index}`"
        class="collection__body__column"
      >
        <router-link
          v-for="(item, index) in column"
          :key="`item-${index}`"
          class="collection__body__column_item"
          :to="{ name: 'Category', params: { categoryName: item.slug } }"
        >
          <span class="collection__body__column_name">
            {{ item.name }}
          </span>
        </router-link>
      </div>
    </div>

    <!-- <div class="navigation__container">
      <span class="navigation__container_title">View: Creators</span>
      <div class="navigation__container_buttons-box">
        <BaseButton
          class="button--white button--oval"
          text="Creators"
          @click.native="goToPage('ArtistsPage')"
        />
        <BaseButton
          class="button--outline-white button--oval"
          text="Back to Home"
          @click.native="goToPage('Home')"
        />
      </div>
    </div> -->
  </div>
</template>

<script>
import { sortedColumns } from "@/helper.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";

export default {
  components: { BaseButton },

  data() {
    return {
      sortedMainCategoriesList: [],
    };
  },

  head: {
    title: function () {
      return {
        inner: "GH - Categories",
        separator: " ",
        complement: " ",
      };
    },
    meta: function () {
      return [];
    },
  },

  computed: {
    ...mapGetters("publicCategories", {
      categoriesList: "getCategoriesList",
      categoryTypes: "getCategoryTypes",
    }),

    getColumnsAmount() {
      const width = window.innerWidth;
      if (width < 540) {
        return 1;
      } else if (width < 768) {
        return 2;
      } else if (width < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
  },

  async created() {
    await this.apiGetCategoriesList({ type: 1 });
    this.sortedMainCategoriesList = sortedColumns(
      this.categoriesList.sub_categories,
      this.getColumnsAmount
    ); //to get columns

    const breadcrumbs = [
      {
        title: "Home",
        to: "/",
      },
      {
        title: "Categories",
        to: "/categories",
      },
    ];
    this.setBreadcrumbs(breadcrumbs);
  },

  methods: {
    ...mapActions("publicCategories", ["apiGetCategoriesList", "apiGetCategoryTypes"]),
    ...mapMutations(["setBreadcrumbs"]),

    goToPage(page, slug) {
      this.$router.push({ name: page, params: { categoryName: slug } });
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
}

.collection {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $lightBlack;
  min-height: 90vh;
  max-width: 160rem;
  padding: 0 5rem;
  margin: 0 auto;
  font-size: 2.1rem;
  line-height: 3.5rem;
  @media only screen and (max-width: $xxs) {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }

  &__header {
    margin-top: 156px;
    margin-bottom: 80px;
    @media only screen and (max-width: $sm) {
      margin-bottom: 60px;
    }
    @media only screen and (max-width: $xxs) {
      margin-top: 28.3rem;
      padding: 0rem 2.8rem;
    }

    &_title {
      display: inline-block;
      text-align: center;
      font-family: $fontBodoni;
      font-size: 6.2rem;
      line-height: 6.8rem;
      @media only screen and (max-width: $xxs) {
        font-size: 4rem;
        line-height: 6rem;
      }
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem 2rem;
    margin-bottom: 90px;
    @media only screen and (max-width: $sm) {
      margin-bottom: 60px;
    }
    button {
      width: calc((100% - 6rem) / 4);
      @media only screen and (max-width: $lg) {
        width: calc((100% - 4rem) / 3);
      }
      @media only screen and (max-width: $sm) {
        width: calc((100% - 2rem) / 2);
      }
    }
    &_title {
      width: 100%;
      color: #888888;
      font-size: 18px;
      margin-bottom: 28px;
    }
  }

  &__body {
    width: 100%;
    margin-bottom: 60px;
    @media only screen and (max-width: $xs) {
      width: 100%;
      display: block;
      text-align: center;
    }

    display: flex;
    justify-content: space-between;

    &__column {
      &_item {
        display: block;
        text-decoration: none;
        margin-bottom: 6px;
      }

      &_name {
        font-size: 2.1rem;
        line-height: 3.5rem;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .navigation__container {
    display: flex;
    flex-direction: column;
    margin: 16.5rem 0rem 10.4rem;
    padding-left: 30%;
    width: 100%;
    @media only screen and (max-width: $xs) {
      padding-left: 15%;
    }
    @media only screen and (max-width: $xxs) {
      margin: 9.3rem 0rem 18.9rem;
      padding-left: 2.6rem;
    }
    &_title {
      font-size: 3.8rem;
      line-height: 4.8rem;
      margin: 0rem 0rem 5rem 1.2rem;
      font-family: $fontBodoni;
      @media only screen and (max-width: $xxs) {
        font-size: 2.4rem;
        line-height: 3rem;
        margin: 0rem 0rem 3rem 0rem;
      }
    }
    &_buttons-box {
      display: flex;
      align-items: center;
      @media only screen and (max-width: $sm) {
        flex-wrap: wrap;
      }
      &::after {
        content: "";
        background-color: $white;
        height: 1px;
        flex-grow: 100;
      }
      .button--oval {
        margin-left: 12px;
        font-family: $fontBodoni;
        @media only screen and (max-width: $sm) {
          margin-left: 0px;
          padding: 12px 18px;
        }
      }
      @media only screen and (max-width: $sm) {
        .button--white {
          width: 60%;
        }
        .button--outline-white {
          margin-top: 1.8rem;
        }
      }
    }
  }
}
</style>

